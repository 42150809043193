<template>
  <ClientOnly>
    <div class="product-wholesale-price-section">
      <table class="">
        <tbody class="divide-y divide-gray-200">
        <tr v-if="selectedVariant?.primaryStockData?.wholesaleListPrice > 0 || selectedVariant?.primaryStockData?.discountPrice > 0">
          <th scope="col" class="text-primary p-2 text-left text-base font-medium">
            {{ $t('listing.list-price') }}
          </th>
          <td class="p-2 text-right text-base text-gray-500 font-bold space-y-1">
            <PriceUpdateModeDescriptionForB2B>
              <div class="inline-flex items-center gap-1 ">
                <DiscountPercent :selected-variant="selectedVariant" direction="horizontal" class="!relative !inline-flex items-center !m-0" />
                <div class="relative">
                  <div class="border-t -rotate-12 absolute top-0 left-0 w-full translate-y-2.5 border-gray-500"></div>
                  <template v-if="(selectedVariant?.primaryStockData?.discountPrice > selectedVariant?.primaryStockData?.wholesalePrice && showListPrice && showListPriceAndCampaignTogether === 1) || !discountPriceExists || showListPriceAndCampaignTogether === 2">
                    {{ formatPrice(selectedVariant?.primaryStockData?.wholesaleListPrice, selectedVariant?.primaryStockData?.currency) }}
                  </template>
                  <template v-else>
                    {{ formatPrice(selectedVariant?.primaryStockData?.discountPrice, selectedVariant?.primaryStockData?.currency) }}
                  </template>
                </div>

                <div v-if="selectedVariant?.primaryStockData?.discountPrice > selectedVariant?.primaryStockData?.wholesalePrice && showListPrice && showListPriceAndCampaignTogether === 1">
                  {{ formatPrice(selectedVariant?.primaryStockData?.discountPrice, selectedVariant?.primaryStockData?.currency) }}
                </div>
              </div>
              <!-- KDV Bilgilendirmesi ve Dahil / Hariç Durumu -->
              <SectionProductVatRateInformation class="flex-1" :selected-variant="selectedVariant" />
            </PriceUpdateModeDescriptionForB2B>
          </td>
        </tr>

        <!-- Toptan Fiyatı Başlangıç -->
        <template v-if="selectedVariant?.primaryStockData?.wholesaleCardPrice === selectedVariant?.primaryStockData?.wholesaleCashPrice">
          <tr>
            <th scope="col" class="text-primary p-2 text-left text-base font-medium">
              {{ $t('listing.wholesale-price') }}
            </th>
            <td class="text-price-color p-2 text-right text-base font-bold space-y-1">
              <PriceUpdateModeDescriptionForB2B>
                <div class="text-lg">
                  {{ formatPrice(selectedVariant?.primaryStockData?.wholesalePrice, selectedVariant?.primaryStockData?.currency) }}
                </div>
                <!-- KDV Bilgilendirmesi ve Dahil / Hariç Durumu -->
                <SectionProductVatRateInformation class="flex-1" :selected-variant="selectedVariant" />
              </PriceUpdateModeDescriptionForB2B>
            </td>
          </tr>
        </template>

        <template v-else>
          <tr>
            <th scope="col" class="text-primary p-2 text-left text-base font-medium">
              {{ $t('listing.wholesale-cash-price') }}
            </th>
            <td class="text-secondary p-2 text-right text-base font-bold space-y-1">
              <PriceUpdateModeDescriptionForB2B>
                <div>
                  {{ formatPrice(selectedVariant?.primaryStockData?.wholesaleCashPrice, selectedVariant?.primaryStockData?.currency) }}
                </div>
                <!-- KDV Bilgilendirmesi ve Dahil / Hariç Durumu -->
                <SectionProductVatRateInformation class="flex-1" :selected-variant="selectedVariant" />
              </PriceUpdateModeDescriptionForB2B>
            </td>
          </tr>
          <tr>
            <th scope="col" class="text-primary p-2 text-left text-base font-medium">
              {{ $t('listing.wholesale-card-price') }}
            </th>
            <td class="text-secondary p-2 text-right text-base font-bold space-y-1">
              <PriceUpdateModeDescriptionForB2B>
                <div>{{ formatPrice(selectedVariant?.primaryStockData?.wholesaleCardPrice, selectedVariant?.primaryStockData?.currency) }}</div>
                <!-- KDV Bilgilendirmesi ve Dahil / Hariç Durumu -->
                <SectionProductVatRateInformation class="flex-1" :selected-variant="selectedVariant" />
              </PriceUpdateModeDescriptionForB2B>
            </td>
          </tr>
        </template>
        <!-- Toptan Fiyatı Bitiş -->

        <tr v-if="selectedVariant?.primaryStockData?.customerPrice">
          <th scope="col" class="text-primary py-3.5 pl-4 pr-3 text-left text-base font-semibold sm:pl-6 md:pl-0">
            {{ $t('listing.customer-price') }}
          </th>
          <td class="text-secondary py-4 pl-3 pr-4 text-right text-base sm:pr-6 md:pr-0 space-y-1">
            <PriceUpdateModeDescriptionForB2B>
              <div>{{ formatPrice(selectedVariant?.primaryStockData?.customerPrice, selectedVariant?.primaryStockData?.currency) }}</div>
              <!-- KDV Bilgilendirmesi ve Dahil / Hariç Durumu -->
              <SectionProductVatRateInformation class="flex-1" :selected-variant="selectedVariant" />
            </PriceUpdateModeDescriptionForB2B>
          </td>
        </tr>
        <tr>
          <th scope="col" class="text-primary p-2 text-left text-base font-medium" v-if="wholesaleConfig?.only_show_default_warehouse_qty !== 0">
            {{ $t('listing.quantity') }}
          </th>
          <td class="p-2 text-right text-base font-bold" :colspan="wholesaleConfig?.only_show_default_warehouse_qty === 0 ? 2 : 1">
            <div class="cursor-default" v-if="wholesaleConfig?.only_show_default_warehouse_qty === 1">
              {{selectedVariant?.qtySumForAllWarehouse}}
            </div>

            <!-- Ön tanımlı depoya ait adetleri göster" işaretlendiği zaman, default depoda adet yoksa sonrakini gösteriyor -->
            <div class="cursor-default" v-else-if="wholesaleConfig?.only_show_default_warehouse_qty === 2">
              {{ selectedVariant?.primaryStockData?.warehouseName }} : {{parseInt(selectedVariant?.primaryStockData?.quantity)}}
            </div>

            <div v-else-if="wholesaleConfig?.only_show_default_warehouse_qty === 0">
              <WarehouseGrid :product="product" />
            </div>

            <!-- B2B açık olan depo adetlerini ayrı ayrı göster -->
            <WarehousePopover
                class="!py-0"
                :only-show-qty="wholesaleConfig?.only_show_default_warehouse_qty === 3"
                :product="product"
                :variantId="selectedVariant?.variantId"
                v-else
            />

          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </ClientOnly>
</template>

<script setup>
const storeConfig = useStoreConfig()
const { wholesaleConfig } = storeToRefs(storeConfig)

const {isVatExcludeForB2B} = useHelper()
const onlyShowQty = ref(false)

const {
  selectedVariant,
  product,
  discountPriceExists
} = await useProduct()

const {
  showListPrice,
  showListPriceAndCampaignTogether
} = useListing()

const strikethroughPrice = computed(() => unref(selectedVariant)?.primaryStockData?.discountPrice ? unref(selectedVariant)?.primaryStockData?.discountPrice : unref(selectedVariant)?.primaryStockData?.listPrice)

</script>